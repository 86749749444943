import {
  EventType,
  ExportPointCloudProperties,
} from "@/analytics/analytics-events";
import { runtimeConfig } from "@/runtime-config";
import { selectHasUserInteractedWithBoxControls } from "@/store/modes/clipping-box-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import { appId } from "@/utils/appid";
import {
  selectAncestor,
  selectIElementWorldMatrix,
  selectProjectId,
} from "@faro-lotv/app-component-toolbox";
import {
  ExclamationMarkIcon,
  ExportIcon,
  HelpBanner,
  ResetIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
  useToast,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericDataset,
  isIElementGenericPointCloudStream,
} from "@faro-lotv/ielement-types";
import {
  PointCloudFormat,
  useApiClientContext,
  useCoreApiClient,
} from "@faro-lotv/service-wires";
import { Badge, Collapse, Stack, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Matrix4, Vector3 } from "three";
import { OBB } from "three/examples/jsm/math/OBB";
import { useCurrentScene } from "../mode-data-context";
import {
  CUSTOM_DENSITY_VALUES,
  ClippingBoxExportDialog,
} from "./clipping-box-export-dialog";
import {
  useClippingBoxContext,
  useClippingBoxPlanes,
} from "./clipping-box-mode-context";
import { canExport } from "./pc-export-utils";
import { planesToArray } from "./planes-to-array";

/** @returns The overlay for the clipping box mode */
export function ClippingBoxModeOverlay(): JSX.Element {
  const { onBoxReset } = useClippingBoxContext();
  const clippingBoxPlanes = useClippingBoxPlanes();

  const pointCloud = useCurrentScene().main;
  assert(
    pointCloud && isIElementGenericPointCloudStream(pointCloud),
    "An active pointcloud is required for the ClippingBox tool",
  );

  const worldMatrixYUp = useAppSelector(
    selectIElementWorldMatrix(pointCloud.id),
  );

  const hasUserInteractedWithBoxControls = useAppSelector(
    selectHasUserInteractedWithBoxControls,
  );

  const { pointCloudApiClient } = useApiClientContext();
  const [formats, setFormats] = useState<PointCloudFormat[]>();
  const [useCustomDensity, setUseCustomDensity] = useState(false);
  const [density, setDensity] = useState<number>(
    CUSTOM_DENSITY_VALUES[0].value,
  );

  useEffect(() => {
    const ac = new AbortController();
    pointCloudApiClient.getFormats(ac.signal).then(setFormats);
    return () => ac.abort();
  }, [pointCloudApiClient]);

  const coreApiClient = useCoreApiClient(
    runtimeConfig.backendEndpoints.coreApiUrl,
    appId(),
  );

  const pointCloudName = useAppSelector((state) => {
    const section = selectAncestor(pointCloud, isIElementGenericDataset)(state);
    return section?.name ?? "pointcloud";
  });

  const projectId = useAppSelector(selectProjectId);

  const [showExportDialog, setShowExportDialog] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);

  // Temporary data to not re-allocate objects at every mouse movement
  const volumeTempData = useRef({
    obb: new OBB(),
    sizes: new Vector3(),
    planesMatrix: new Matrix4(),
  });

  // Current desired export volume by the user in cubic meters
  const exportVolume = useMemo(() => {
    if (!clippingBoxPlanes) return 0;
    const { obb, planesMatrix, sizes } = volumeTempData.current;
    planesMatrix.fromArray(planesToArray(clippingBoxPlanes));
    obb.center.set(0, 0, 0);
    obb.rotation.identity();
    obb.halfSize.set(0.5, 0.5, 0.5);
    obb.applyMatrix4(planesMatrix);
    const { x, y, z } = obb.getSize(sizes);
    return Math.abs(x) * Math.abs(y) * Math.abs(z);
  }, [clippingBoxPlanes]);

  const { openToast } = useToast();
  const exportSubVolume = useCallback(
    async (format: string) => {
      if (!projectId || !clippingBoxPlanes || !formats) {
        return;
      }
      Analytics.track<ExportPointCloudProperties>(EventType.exportPointCloud, {
        format,
        density,
      });

      try {
        setExportInProgress(true);
        // Convert the PointCloud Y-Up column-major world transform to Z-Up row-major
        const transform = new Matrix4()
          .fromArray(worldMatrixYUp)
          .premultiply(new Matrix4().makeRotationX(Math.PI / 2))
          .transpose();

        const {
          data: { userId },
        } = await coreApiClient.getLoggedInUser();

        await pointCloudApiClient.exportSubVolume({
          userId,
          pointCloudId: pointCloud.id,
          projectId,
          format,
          fileName: `${pointCloudName}_export`,
          boundingBox: planesToArray(clippingBoxPlanes),
          transformation: transform.toArray(),
          minimumPointSpacing: density / 1000,
        });
        setShowExportDialog(false);
      } catch {
        openToast({
          title: "Point cloud export failed",
          message: "Something went wrong. Try again later.",
          variant: "error",
        });
      }
      setExportInProgress(false);
    },
    [
      clippingBoxPlanes,
      coreApiClient,
      density,
      formats,
      openToast,
      pointCloud.id,
      pointCloudApiClient,
      pointCloudName,
      projectId,
      worldMatrixYUp,
    ],
  );

  /** true if the current volume is too big for at least one export format */
  const isVolumeTooBig = !!formats?.some(
    (format) => !canExport(format, density, exportVolume),
  );

  const [shouldDisableExport, exportTooltipMessage] = useMemo(() => {
    if (!clippingBoxPlanes) {
      return [true, "The selection is too small or contains no points"];
    }
    if (!formats) {
      return [true, "The export backend is not available. Retry later"];
    }
    if (isVolumeTooBig) {
      return [
        false,
        `The selected volume of ${Math.ceil(
          exportVolume,
        )} m\u00B3 is too big for some of the available export formats`,
      ];
    }
    return [false, undefined];
  }, [clippingBoxPlanes, exportVolume, formats, isVolumeTooBig]);

  return (
    <>
      {formats && (
        <ClippingBoxExportDialog
          open={showExportDialog}
          formats={formats}
          exportVolume={exportVolume}
          showSpinner={exportInProgress}
          disabled={exportInProgress}
          useCustomDensity={useCustomDensity}
          density={density}
          onUseCustomDensityChanged={setUseCustomDensity}
          onDensityChanged={setDensity}
          onClose={() => {
            Analytics.track(EventType.cancelPointCloudExport);
            setShowExportDialog(false);
          }}
          onConfirm={exportSubVolume}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {/* first empty column of a three column layout */}
        <div />
        <Collapse in={!hasUserInteractedWithBoxControls}>
          <HelpBanner>
            Drag the manipulators to resize the box. Click on the export volume
            button to download it.
          </HelpBanner>
        </Collapse>
        <Stack justifyContent="center">
          <Toolbar>
            <ToolGroup>
              <Tooltip
                title="Reset Clipping Box"
                placement="left"
                aria-label="reset clipping box"
              >
                <ToolButton onClick={onBoxReset}>
                  <ResetIcon sx={{ width: "24px", height: "24px" }} />
                </ToolButton>
              </Tooltip>
              <Tooltip
                aria-label="export sub volume"
                title={`Export Sub Volume ${
                  exportTooltipMessage ? ` - ${exportTooltipMessage}` : ""
                }`}
                placement="left"
              >
                <ToolButton
                  onClick={() => {
                    Analytics.track(EventType.startPointCloudExport);
                    setShowExportDialog(true);
                  }}
                  disabled={shouldDisableExport}
                >
                  <StartExportIcon showBadge={isVolumeTooBig} />
                </ToolButton>
              </Tooltip>
            </ToolGroup>
          </Toolbar>
        </Stack>
      </Stack>
    </>
  );
}

type ExportIconProps = {
  /** true to show the warning badge */
  showBadge: boolean;
};

/**
 * @returns Icon for the volume export action with an optional warning badge
 */
function StartExportIcon({ showBadge }: ExportIconProps): JSX.Element {
  return (
    <Badge
      badgeContent={<ExclamationMarkIcon sx={{ width: "100%" }} />}
      color="warning"
      overlap="circular"
      invisible={!showBadge}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{
        "& .MuiBadge-badge": {
          border: (theme) => `1px solid ${theme.palette.darkGrey80}`,
        },
      }}
    >
      <ExportIcon sx={{ width: "24px", height: "24px" }} />
    </Badge>
  );
}
