import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { useObjectView } from "@/hooks/use-object-view";
import { useCached3DObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  centerCameraOnBoxAroundY,
  createClippingPlanes,
  createClippingPlanesMatrix,
  matrixToBoxAroundY,
} from "@/utils/volume-utils";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
} from "@faro-lotv/app-component-toolbox";
import { blue } from "@faro-lotv/flat-ui";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { assert } from "@faro-lotv/lotv";
import { selectIElementWorldTransform } from "@faro-lotv/project-source";
import { OrthographicCamera } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { Group, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry } from "three";
import { useCurrentScene } from "../mode-data-context";
import { useClippingBoxPlanes } from "./clipping-box-mode-context";

type OverviewImagePreviewProps = {
  /** Flag to show the helper plane */
  showBackgroundPlane: boolean;

  /**
   * Callback to be called when the aspect ratio of the object changes.
   * For example, when the clipping box changes
   */
  onAspectRatioChanged?(aspectRatio: number): void;
};

/**
 * This component is used to render a top-down view of the pointcloud in the scene.
 * The object is framed by the camera and the clipping box is applied.
 *
 * @returns a top-down view of the pointcloud in the scene
 */
export function OverviewImagePreview({
  showBackgroundPlane,
  onAspectRatioChanged,
}: OverviewImagePreviewProps): JSX.Element {
  const { size, camera } = useThree();
  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "The overview image preview requires a point cloud",
  );

  const transform = useAppSelector(selectIElementWorldTransform(main.id));
  const pointCloud = useCached3DObject(main);

  // Create a view of the pointcloud so that it can be shown both in the main scene and the overview
  const view = useObjectView(pointCloud);

  const clippingPlanesBox = useClippingBoxPlanes();

  // Clipping planes correctly positioned in the scene
  const pcClippingBoxPlanes = useMemo(() => {
    if (!clippingPlanesBox) return;
    return createClippingPlanes(clippingPlanesBox, transform);
  }, [clippingPlanesBox, transform]);

  const pcClippingPlanes = useMemo(() => {
    return pcClippingBoxPlanes
      ? [...pcClippingBoxPlanes.min, ...pcClippingBoxPlanes.max]
      : undefined;
  }, [pcClippingBoxPlanes]);

  // Group used to properly position the helper plane
  const group = useMemo(() => new Group(), []);

  // Update the camera position and orientation to frame the pointcloud
  useEffect(() => {
    if (!pcClippingBoxPlanes) return;
    // From the clipping planes, get a matrix that represents the clipping box of the pointcloud
    const matrix = createClippingPlanesMatrix(pcClippingBoxPlanes);

    // Get the center,size and the rotation around Y of the box
    const boxInfo = matrixToBoxAroundY(matrix);

    onAspectRatioChanged?.(boxInfo.size.x / boxInfo.size.z);

    // Frame the camera on the box
    centerCameraOnBoxAroundY(camera, boxInfo, size);

    // Update the group position, rotation and scale based on the clipping box matrix
    matrix.decompose(group.position, group.quaternion, group.scale);
  }, [camera, group, onAspectRatioChanged, pcClippingBoxPlanes, size]);

  // This is an helper for the user to understand the real size of the overview image
  const planeHelper = useMemo(
    () =>
      new Mesh(
        new PlaneGeometry(1, 1),
        new MeshBasicMaterial({
          transparent: true,
          opacity: 0.3,
          color: blue[100],
        }),
      ),
    [],
  );

  return (
    <>
      <primitive object={group}>
        <primitive
          object={planeHelper}
          quaternion={[-Math.SQRT1_2, 0, 0, Math.SQRT1_2]}
          position={[0.5, 0, 0.5]}
          visible={showBackgroundPlane}
        />
      </primitive>
      <OrthographicCamera makeDefault />
      <PointCloudRenderer pointCloud={view} clippingPlanes={pcClippingPlanes} />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={view} />
        <FilteredRenderPass
          filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
