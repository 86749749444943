import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/foundation";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";

export enum ToolName {
  annotation = "annotation",
  clipping = "clipping",
  measurement = "measurement",
  sendTo = "sendTo",
  desaturateSheet = "desaturateSheet",
  opacity = "opacity",
  heatmap = "heatmap",
  clipScene = "clipScene",
  analysis = "analysis",
}

export enum ToolVisibility {
  visible = "visible",
  hidden = "hidden",
  disabled = "disabled",
  waiting = "waiting",
}

/** Possible actions the user can trigger to exit an Exclusive Mode */
export enum ExclusiveModeCompletionAction {
  /** Exit the exclusive mode without applying any changes */
  back = "back",

  /** Exit the exclusive mode and apply all the pending changes */
  apply = "apply",
}

export type UIState = {
  // TODO: Evaluate this state as it seems like it's never changed (i.e. setShowSpinner is never imported)
  // See https://faro01.atlassian.net/browse/SWEB-1447
  /** Show the full screen modal spinner */
  showSpinner: boolean;

  /** The tool that is currently selected as active */
  activeTool: ToolName | null;

  /**
   * The settings menu that is currently opened.
   * The idea is that many mutually exclusive buttons can open
   * different settings menus, but only one menu can be opened
   * at a time.
   */
  activeSettingsMenu: ToolName | null;

  /** Visibility state of the individual tools within the Toolbar component */
  toolVisibility: Record<ToolName, ToolVisibility>;

  /** Open/Collapsed state of the project overview */
  isProjectOverviewExpanded: boolean;

  /** Open/Collapsed state quick help */
  isQuickHelpOpen: boolean;

  /**
   * What action to exit the exclusive mode is currently in progress
   *
   * This value will change to one of the actions that the user can trigger to exit an ExclusiveMode
   * (back or apply) when that async action starts and then return to undefined when the action finishes
   *
   * It can be used to disable ui components while the exclusive mode is committing changes to the project
   * or making other state changes.
   */
  exclusiveModeCompletionAction?: `${ExclusiveModeCompletionAction}`;

  /** IDs of the open annotations in the viewer */
  expandedAnnotationsIds: GUID[];
};

export const initialState: Readonly<UIState> = Object.freeze({
  showSpinner: false,

  activeTool: null,

  activeSettingsMenu: null,

  isProjectOverviewExpanded: true,

  isQuickHelpOpen: false,

  // Initially set all tools to be hidden
  toolVisibility: Object.values(ToolName).reduce(
    (visibilities: Record<string, ToolVisibility>, name) => {
      visibilities[name] = ToolVisibility.hidden;
      return visibilities;
    },
    {},
  ),

  expandedAnnotationsIds: [],
});

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    /**
     * Change the current state of the flag showSpinner
     *
     * @param state Current state
     * @param action True if the spinner is showed
     */
    setShowSpinner(state, action: PayloadAction<boolean>) {
      state.showSpinner = action.payload;
    },

    /**
     * Mark the given tool as active
     *
     * @param state Current state
     * @param action The name of the tool to active
     */
    activateTool(state, action: PayloadAction<ToolName>) {
      state.activeTool = action.payload;
    },

    /**
     *
     * @param state Current state
     * @param action Name of settings menu to be active
     */
    setActiveSettingsMenu(state, action: PayloadAction<ToolName | null>) {
      state.activeSettingsMenu = action.payload;
    },

    /**
     * Deactivate the currently active tool
     *
     * @param state Current state
     */
    deactivateTool(state) {
      state.activeTool = null;
    },

    /**
     * Change the visibility of a tool
     *
     * @param state Current state
     * @param action Name of the tool to change to the provided visibility
     */
    changeToolVisibility(
      state,
      action: PayloadAction<{
        name: ToolName;
        visibility: ToolVisibility;
      }>,
    ) {
      state.toolVisibility[action.payload.name] = action.payload.visibility;
    },

    /**
     * Set the visibility of the project overview
     *
     * @param state Current state
     * @param action The target state of the project overview open state
     */
    setIsProjectOverviewExpanded(state, action: PayloadAction<boolean>) {
      state.isProjectOverviewExpanded = action.payload;
    },
    /**
     * Set the visibility of the quick help
     *
     * @param state Current state
     * @param action The target state of the quick help panel in mode
     */
    setIsQuickHelpOpen(state, action: PayloadAction<boolean>) {
      state.isQuickHelpOpen = action.payload;
    },

    /**
     * Notify what completion action for the exclusive mode is currently executing
     *
     * @param state Current state
     * @param action The new exclusive mode completion action executing, or undefined if none is executing now
     */
    setExclusiveModeCompletionAction(
      state,
      action: PayloadAction<`${ExclusiveModeCompletionAction}` | undefined>,
    ) {
      state.exclusiveModeCompletionAction = action.payload;
    },

    /**
     * Expand/collapse an annotation
     *
     * @param state Current state
     * @param action The id of the annotation and the new expansion state
     */
    setAnnotationExpansion(
      state,
      action: PayloadAction<{ id: GUID; expanded: boolean }>,
    ) {
      if (action.payload.expanded) {
        state.expandedAnnotationsIds = uniq([
          ...state.expandedAnnotationsIds,
          action.payload.id,
        ]);
      } else {
        state.expandedAnnotationsIds = state.expandedAnnotationsIds.filter(
          (id) => id !== action.payload.id,
        );
      }
    },

    /**
     * Collapse all annotations
     *
     * @param state Current state
     */
    collapseAllAnnotations(state) {
      state.expandedAnnotationsIds = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const uiReducer = uiSlice.reducer;

export const {
  activateTool,
  setActiveSettingsMenu,
  changeToolVisibility,
  deactivateTool,
  setShowSpinner,
  setIsProjectOverviewExpanded,
  setIsQuickHelpOpen,
  setExclusiveModeCompletionAction,
  setAnnotationExpansion,
  collapseAllAnnotations,
} = uiSlice.actions;
