import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { shallowEqual } from "react-redux";
import { selectRevisionEntities } from "../store/revision-selectors";
import { ScanTree } from "./scan-tree/scan-tree";

type DataPreparationSidebarProps = {
  /** The title of the current step. */
  title: ReactNode;

  /** The description of the current step. */
  description: ReactNode;

  /** The actions that can be used in the data preparation step. */
  buttons?: ReactNode;
};

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar({
  title,
  description,
  buttons,
}: DataPreparationSidebarProps): JSX.Element {
  const entities = useAppSelector(selectRevisionEntities, shallowEqual);

  return (
    <HorizontalResizeContainer
      initialWidth={320}
      minWidth={200}
      maxWidth={500}
      handleSide="right"
    >
      <Stack
        justifyContent="space-between"
        flexShrink={0}
        sx={{
          width: "100%",
          height: "100%",
          px: 1.5,
          py: 3,
        }}
      >
        <Stack gap={2} height="100%">
          <Stack gap={1.5}>
            <FaroText variant="heading16">{title}</FaroText>
            <FaroText variant="bodyM">{description}</FaroText>
          </Stack>

          <ScanTree entities={entities} />
        </Stack>

        {/* Buttons */}
        <Stack gap={1}>{buttons}</Stack>
      </Stack>
    </HorizontalResizeContainer>
  );
}
