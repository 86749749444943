import { FaroChipFilter } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/system";

export type FilterMenuRowProps = {
  /** true if the filter menu row should be visible */
  isVisible: boolean;
};

/** @returns the row of filter menus for the annotation list */
export function FilterMenuRow({ isVisible }: FilterMenuRowProps): JSX.Element {
  return (
    <Stack
      direction="row"
      sx={{
        my: 1,
        gap: 0.5,
        height: isVisible ? "40px" : 0,
        transition: "height 0.25s ease-in",
        transitionBehavior: "allow-discrete",
        overflowX: "scroll",
        overflowY: "clip",
      }}
    >
      {/** TODO: Assignee Menu (https://faro01.atlassian.net/browse/SWEB-5345) */}
      <FaroChipFilter label="User" onClick={() => {}} />
      {/** TODO: Tags Menu (https://faro01.atlassian.net/browse/SWEB-5346) */}
      <FaroChipFilter label="Tags" onClick={() => {}} />
      {/** TODO: Status Menu (https://faro01.atlassian.net/browse/SWEB-5347) */}
      <FaroChipFilter label="Status" onClick={() => {}} />
      {/** TODO: DueDate Menu (https://faro01.atlassian.net/browse/SWEB-5348) */}
      <FaroChipFilter label="Due Date" onClick={() => {}} />
    </Stack>
  );
}
