import { PointCloudObject } from "@/object-cache";
import { useMultiCloudPointBudgetManager } from "@/registration-tools/common/rendering/use-multi-cloud-point-budget-manager";
import { usePointCloudMaterials } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import { useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { useMemo } from "react";
import { Plane } from "three";
import { selectScanColoring } from "../store/data-preparation-view-options/data-preparation-view-options-selectors";
import { RevisionScanRenderer } from "./revision-scan-renderer";

export type CaptureTreeScansProps = {
  scanEntities: RevisionScanEntity[];

  pointCloudObjects: PointCloudObject[];

  /** The point clouds to highlight in a hover interaction */
  hoveredPointCloudIds?: GUID[];

  /** The point clouds to highlight in a selection interaction */
  selectedPointCloudIds?: GUID[];

  /** Clipping planes to use for rendering */
  clippingPlanes?: Plane[];

  /** Callback to execute when the scans are placed at their initial position. */
  onInitialPositioning(): void;
};

/** @returns Render the scans from the capture tree at the position of their revisions */
export function RevisionScansRenderer({
  scanEntities,
  pointCloudObjects,
  hoveredPointCloudIds,
  selectedPointCloudIds,
  clippingPlanes,
  onInitialPositioning,
}: CaptureTreeScansProps): JSX.Element {
  const scanColoring = useAppSelector(selectScanColoring);

  const highlightedIds = useMemo(
    () => [...(hoveredPointCloudIds ?? []), ...(selectedPointCloudIds ?? [])],
    [hoveredPointCloudIds, selectedPointCloudIds],
  );

  usePointCloudMaterials({
    pointClouds: pointCloudObjects,
    scanColoring,
    highlightedIds,
    clippingPlanes,
  });

  useMultiCloudPointBudgetManager(pointCloudObjects);

  return (
    <>
      {scanEntities.map((scanEntity) => (
        <RevisionScanRenderer
          key={scanEntity.id}
          scanEntity={scanEntity}
          onInitialPositioning={onInitialPositioning}
        />
      ))}
    </>
  );
}
