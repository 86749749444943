import { useFileUploader } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { VolumeInfo } from "@/utils/volume-utils";
import { parseImageFromFile, resizeImage } from "@faro-lotv/flat-ui";
import { assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  computeReferenceSystemProperties,
  fetchProjectIElements,
  selectChildDepthFirst,
  selectProjectId,
  selectRootIElement,
} from "@faro-lotv/project-source";
import {
  createMutationAddArea,
  createMutationAddVolume,
  createMutationSetAreaWorldPose,
  mutationAddImgSheet,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";

type CreateAreaAndVolumeParams = {
  /** Name that will be assigned to the area and sheet */
  areaName: string;
  /** Image file that will be used for the sheet */
  file: File;
  /** Optional volume of the area */
  volume?: VolumeInfo;
};

type CreateAreaAndVolumeCallback = ({
  areaName,
  file,
  volume,
}: CreateAreaAndVolumeParams) => void;

/**
 * Hook that creates an area, a volume and a sheet in the project
 *
 * @returns a function that creates the area and the volume.
 */
export function useCreateAreaAndVolume(): CreateAreaAndVolumeCallback {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const root = useAppSelector(selectRootIElement);
  const projectId = useAppSelector(selectProjectId);

  const uploadFile = useFileUploader();
  const { coreApiClient, projectApiClient } = useApiClientContext();

  const slideContainer = useAppSelector(
    selectChildDepthFirst(root, (iElement: IElement) =>
      isIElementWithTypeAndHint(
        iElement,
        IElementType.group,
        IElementTypeHint.slideContainer,
      ),
    ),
  );

  // This will create a new area in the project
  // update its pose and then
  // create an overview image and a volume
  return useCallback(
    async ({ areaName, file, volume }: CreateAreaAndVolumeParams) => {
      if (!projectId || !slideContainer) return;

      // Create a new area in the project
      const area = generateGUID();

      // Get the width and height of the image
      const { width: imageWidth, height: imageHeight } =
        await parseImageFromFile(file);

      // Resize the image to a thumbnail
      const thumbnailFile = await resizeImage(file);

      const [{ imageUrl, md5 }, { thumbnailUrl }] = await Promise.all([
        // Upload the image file to the backend
        // Wait for the upload to complete
        new Promise<{
          imageUrl: string;
          md5: string;
        }>((resolve, reject) => {
          uploadFile({
            file,
            uploadElementType: UploadElementType.none,
            projectId,
            coreApiClient,
            onUploadCompleted: (_, imageUrl, md5) => resolve({ imageUrl, md5 }),
            onUploadFailed: reject,
          });
        }),

        // Upload the thumbnail file to the backend
        // Wait for the upload to complete
        new Promise<{ thumbnailUrl: string }>((resolve, reject) => {
          uploadFile({
            file: thumbnailFile,
            uploadElementType: UploadElementType.none,
            projectId,
            coreApiClient,
            onUploadCompleted: (_, thumbnailUrl) => resolve({ thumbnailUrl }),
            onUploadFailed: reject,
          });
        }),
      ]);

      // Make sure the uploads were successful
      assert(
        imageUrl && md5 && thumbnailUrl,
        "Could not upload the image file",
      );

      // Apply the mutations to create the area, the volume and the sheet
      await projectApiClient.applyMutations([
        createMutationAddArea({
          id: area,
          rootId: slideContainer.rootId,
          groupId: slideContainer.id,
          name: areaName,
        }),
        mutationAddImgSheet({
          rootId: slideContainer.rootId,
          sectionId: area,
          name: areaName,
          fileName: file.name,
          fileSize: file.size,
          md5Hash: md5,
          uri: imageUrl,
          thumbnailUri: thumbnailUrl,
          pixelWidth: imageWidth,
          pixelHeight: imageHeight,
          refCoordSystemMatrix: computeReferenceSystemProperties(
            { type: IElementType.imgSheet, typeHint: IElementTypeHint.area },
            false,
          ),
        }),
        // Add the volume and set the area pose using it, if it was provided
        ...(volume
          ? [
              createMutationSetAreaWorldPose(
                area,
                1,
                volume.rotation,
                volume.position,
              ),
              createMutationAddVolume({
                areaId: area,
                rootId: slideContainer.rootId,
                size: volume.size ?? { x: 1, y: 1, z: 1 },
                userId: user?.id,
              }),
            ]
          : []),
      ]);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        fetchProjectIElements({
          // eslint-disable-next-line require-await -- FIXME
          fetcher: async () =>
            projectApiClient.getAllIElements({
              ancestorIds: [slideContainer.id],
            }),
        }),
      );
    },
    [
      projectId,
      slideContainer,
      uploadFile,
      coreApiClient,
      projectApiClient,
      user?.id,
      dispatch,
    ],
  );
}
