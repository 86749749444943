import { useCreateAreaAndVolume } from "@/hooks/use-create-area-and-volume";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { volumeFromPlanes } from "@/utils/volume-utils";
import {
  Canvas,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { FaroButton, FaroText, TextField, neutral } from "@faro-lotv/flat-ui";
import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { LotvRenderer, assert } from "@faro-lotv/lotv";
import { Box, Checkbox, Drawer, FormControlLabel, Stack } from "@mui/material";
import { PerformanceMonitor } from "@react-three/drei";
import { useCallback, useMemo, useState } from "react";
import { Color } from "three";
import { useComponentScreenshot } from "../../hooks/use-component-screenshot";
import { useCurrentScene } from "../mode-data-context";
import { useClippingBoxPlanes } from "./clipping-box-mode-context";
import { selectNewAreaDefaultName } from "./clipping-box-selectors";
import { OverviewImagePreview } from "./overview-image-preview";

const DEFAULT_WIDTH = 1024;

/**
 * Check if the area name is valid
 * It must have at least one character and at not more than the backend limit
 *
 * @param areaName The name of the area
 * @returns true if the area name is valid, false otherwise
 */
function isAreaNameValid(areaName: string | undefined): boolean {
  if (!areaName) return false;

  return areaName.length > 0 && areaName.length <= MAX_NAME_LENGTH;
}

/**
 * @returns a sidebar next to the canvas, used for the create area workflow
 */
export function ClippingBoxModeDrawer(): JSX.Element | null {
  const { main } = useCurrentScene();
  assert(main, "There should be a main element in the scene");

  const areaDefaultName = useAppSelector(selectNewAreaDefaultName(main));

  // Use the dataset or data session name as the default area name
  const [areaName, setAreaName] = useState(areaDefaultName ?? "");
  const [shouldCreateAnotherArea, setShouldCreateAnotherArea] = useState(false);

  // If the user has already focused the input field once
  const [isFirstFocus, setIsFirstFocus] = useState(true);
  // If the user has already clicked the create button once
  const [isFirstClick, setIsFirstClick] = useState(true);

  // Error message to show when the area name is invalid
  // It's only shown after the first click or if the user has already focused the input field
  const errorMessage = useMemo(() => {
    if (!isAreaNameValid(areaName) && (!isFirstClick || !isFirstFocus)) {
      return "Name cannot be empty";
    }
  }, [areaName, isFirstClick, isFirstFocus]);

  const shouldShowDrawer = useAppSelector(
    selectHasFeature(Features.CreateArea),
  );

  const [previewObjectAspectRatio, setPreviewObjectAspectRatio] = useState(1);

  // Component rendered in an offscreen canvas to take a screenshot
  // This is what shows up in the screenshot
  // It's using a useMemo to avoid re-rendering too many times the component
  const renderComponent = useMemo(
    () => (
      <OverviewImagePreview
        showBackgroundPlane={false}
        onAspectRatioChanged={setPreviewObjectAspectRatio}
      />
    ),
    [],
  );

  // Assign the static dimension to the biggest side of the object
  // This is done to avoid the screenshot from being too big
  const { screenshotWidth, screenshotHeight } = useMemo(() => {
    if (previewObjectAspectRatio > 1) {
      return {
        screenshotWidth: DEFAULT_WIDTH,
        screenshotHeight: Math.round(DEFAULT_WIDTH / previewObjectAspectRatio),
      };
    }
    return {
      screenshotWidth: Math.round(DEFAULT_WIDTH * previewObjectAspectRatio),
      screenshotHeight: DEFAULT_WIDTH,
    };
  }, [previewObjectAspectRatio]);

  const transform = useAppSelector(selectIElementWorldTransform(main.id));

  const clippingPlanesBox = useClippingBoxPlanes();
  const volumeInfo = volumeFromPlanes(clippingPlanesBox, transform);

  const createNewArea = useCreateAreaAndVolume();

  const onComponentRendered = useCallback(
    async (canvas: HTMLCanvasElement) => {
      // Get the content of the canvas as a blob
      const blob: Blob | null = await new Promise((resolve) =>
        canvas.toBlob(resolve),
      );
      assert(blob, "Could not create a blob from the canvas");

      // Create an image file from the canvas blob
      const imageFile = new File([blob], `${areaName}.png`, {
        type: "image/png",
      });
      assert(imageFile, "Could not create the image file");

      // Create the area, the volume and the sheet
      createNewArea({
        areaName,
        file: imageFile,
        volume: volumeInfo,
      });
    },
    [areaName, createNewArea, volumeInfo],
  );

  // Callback used to generate a screenshot
  const createImage = useComponentScreenshot(
    renderComponent,
    screenshotWidth,
    screenshotHeight,
    onComponentRendered,
  );

  // Create an image only if the area name is valid
  const onCreateButtonClicked = useCallback(() => {
    if (isFirstClick) {
      setIsFirstClick(false);
    }

    if (isAreaNameValid(areaName)) {
      createImage();
    }
  }, [areaName, createImage, isFirstClick]);

  if (!shouldShowDrawer) return null;

  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
          width: 300,
          backgroundColor: neutral[50],
          p: 1.5,
          gap: 3,
        },
      }}
    >
      <FaroText variant="heading16">Areas</FaroText>
      <FaroText variant="placeholder">
        Fill and check the information about your area.
      </FaroText>
      <Stack
        sx={{
          p: 1.5,
          gap: 2,
          borderRadius: 0.5,
          backgroundColor: neutral[0],
        }}
      >
        <TextField
          label="Name"
          placeholder="Area 1"
          text={areaName}
          // Stop the box controls from being triggered
          onKeyDown={(e) => e.stopPropagation()}
          onTextChanged={setAreaName}
          fullWidth
          // Allow only a certain number of characters
          inputProps={{ maxLength: MAX_NAME_LENGTH }}
          maxCharacterCount={MAX_NAME_LENGTH}
          error={errorMessage}
          onFocus={(e) => {
            // Select the text on the first focus, so it's easier to edit the default value
            if (isFirstFocus) {
              setIsFirstFocus(false);
              e.target.select();
            }
          }}
        />
        <Stack>
          <FaroText variant="labelM">Area overview image</FaroText>
          <OverviewImage />
          <FaroText variant="helpText">
            An image of the area will be automatically generated
          </FaroText>
          <FormControlLabel
            label="Create another area"
            control={
              <Checkbox
                checked={shouldCreateAnotherArea}
                onChange={(ev) => setShouldCreateAnotherArea(ev.target.checked)}
              />
            }
            sx={{
              my: 2,
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: neutral[800],
              },
            }}
          />
          <FaroButton onClick={onCreateButtonClicked}>Create</FaroButton>
        </Stack>
      </Stack>
    </Drawer>
  );
}

function OverviewImage(): JSX.Element {
  return (
    <Box
      component="span"
      sx={{
        width: "100%",
        AspectRatio: "1.75",
      }}
    >
      <Canvas
        gl={(canvas) => {
          const renderer = new LotvRenderer({
            canvas,
            premultipliedAlpha: false,
          });
          // enabling the 'localClippingEnabled' property
          // since the app is going to use a global bounding box
          // in most of its scenes.
          renderer.localClippingEnabled = true;
          return renderer;
        }}
        onCreated={(state) => (state.scene.background = new Color(neutral[50]))}
      >
        <PerformanceMonitor>
          <OverviewImagePreview showBackgroundPlane />
        </PerformanceMonitor>
      </Canvas>
    </Box>
  );
}
