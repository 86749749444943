import { useMemo } from "react";
import { Plane } from "three";
import { create } from "zustand";
import { ClippingBoxPlanes } from "./planes-to-array";

export type ClippingBoxContext = {
  /** Function called when the reset clipping box button is clicked */
  onBoxReset?(): void;

  /** Setter for the reset callback function */
  setOnBoxReset(onBoxReset: () => void): void;

  /** The six planes defining the clipping box if a valid set is available */
  clippingPlanes?: Plane[];

  /**
   * Set the six planes defining the clipping box or undefined if there are no valid planes available
   *
   * The planes are valid if all the sizes of the selection box are > 0.01 and
   * the user defined box intersects the point cloud bounding box
   *
   * They selection box may still contain no points
   */
  setClippingPlanes(planes: Plane[] | undefined): void;
};

/** Context containing useful data for the Clipping Box mode */
export const useClippingBoxContext = create<ClippingBoxContext>((set) => ({
  onBoxReset: undefined,
  setOnBoxReset: (onBoxReset: () => void) => set({ onBoxReset }),
  clippingPlanes: undefined,
  setClippingPlanes: (clippingPlanes: Plane[]) => set({ clippingPlanes }),
}));

/**
 * @returns The two triplets of clipping planes that describe the minimum and the maximum of the bounding box
 */
export function useClippingBoxPlanes(): ClippingBoxPlanes | undefined {
  const { clippingPlanes } = useClippingBoxContext();

  return useMemo<ClippingBoxPlanes | undefined>(
    () =>
      clippingPlanes
        ? {
            // The BoxControls defines the clipping planes with normals [-X, +X, -Y, +Y, -Z, +Z], in this order.
            // Since we are using the gizmo z-up, we assume that the minimum is mapped to
            // the intersection of the planes corresponding to +X, +Z, -Y.
            // This way, the matrix will not contain any 90° rotation around X, but just the
            // rotation around Y.
            // We will need to change this if we will ever move to a Z-up viewer.
            min: [clippingPlanes[1], clippingPlanes[5], clippingPlanes[2]],
            max: [clippingPlanes[0], clippingPlanes[4], clippingPlanes[3]],
          }
        : undefined,
    [clippingPlanes],
  );
}
