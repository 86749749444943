import { RootState } from "@/store/store";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  isIElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  DEFAULT_TRANSFORM,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  CaptureTreeEntityRevision,
  RevisionScanEntity,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { EdgesMap } from "./revision-slice";

/**
 * @param state The current application state.
 * @returns All loaded entities in the revision.
 */
export function selectRevisionEntities(
  state: RootState,
): CaptureTreeEntityRevision[] {
  return Object.values(state.revision.entityMap).filter(isValid);
}

/**
 * @param state The current application state.
 * @returns All loaded point cloud scans in the revision.
 */
export function selectRevisionScans(state: RootState): RevisionScanEntity[] {
  return selectRevisionEntities(state).filter(isRevisionScanEntity);
}

/**
 * @param entity The revision entity to get the cached world transform for.
 * @returns The cached world transform of the entity.
 */
export function selectRevisionEntityWorldTransformCache(
  entity?: CaptureTreeEntityRevision,
) {
  return (state: RootState) =>
    entity
      ? state.revision.transformCache[entity.id] ?? DEFAULT_TRANSFORM
      : DEFAULT_TRANSFORM;
}

/**
 * @param scanEntity The scan entity to get the point cloud stream for.
 * @returns The point cloud stream corresponding to the scan entity.
 */
export function selectPointCloudStreamForScanEntity(
  scanEntity?: RevisionScanEntity,
) {
  return (state: RootState) => {
    // The scan entity of the revision has the same ID as the data set IElement in the Capture Tree
    const dataSet = selectIElement(scanEntity?.id)(state);
    return selectChildDepthFirst(
      dataSet,
      isIElementGenericPointCloudStream,
    )(state);
  };
}

/**
 * @param id The ID of the revision entity
 * @returns The revision entity with the given ID or `undefined` if it's not loaded.
 */
export function selectRevisionEntity(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision | undefined =>
    state.revision.entityMap[id];
}

/**
 * @returns a cached map of entities to their children
 * @param state the current application state
 */
const selectRevisionEntityChildrenMap = createSelector(
  [(state: RootState) => state.revision.entityMap],
  (entityMap) => {
    const map: Record<GUID, CaptureTreeEntityRevision[] | undefined> = {};

    for (const entity of Object.values(entityMap)) {
      if (!entity?.parentId) continue;

      let childrenOfParent = map[entity.parentId];

      if (!childrenOfParent) {
        childrenOfParent = [];
        map[entity.parentId] = childrenOfParent;
      }

      childrenOfParent.push(entity);
    }

    return map;
  },
);

// stable reference to avoid unnecessary re-renders
const EMPTY_CHILDREN: CaptureTreeEntityRevision[] = [];

/**
 * @param id the entity id to get the children for
 * @returns the direct children for an entity
 */
export function selectRevisionEntityChildren(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] =>
    selectRevisionEntityChildrenMap(state)[id] ?? EMPTY_CHILDREN;
}

/**
 * @param id the entity to the the children for
 * @returns all children matching a predicate function
 */
export function selectRevisionEntityAllChildren(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] => {
    const children = selectRevisionEntityChildren(id)(state);

    const found: CaptureTreeEntityRevision[] = [];

    for (const child of children) {
      found.push(child);
      found.push(...selectRevisionEntityAllChildren(child.id)(state));
    }

    return found;
  };
}

/**
 * @param id The ID of the scan entity
 * @returns The scan entity with the given ID or `undefined` if it's not loaded.
 * @throws an assertion error if the entity is not a valid scan.
 */
export function selectRevisionEntityScan(id: GUID) {
  return (state: RootState): RevisionScanEntity | undefined => {
    const entity = selectRevisionEntity(id)(state);

    if (!entity) return;

    assert(isRevisionScanEntity(entity));
    return entity;
  };
}

/**
 * @param state The current application state.
 * @returns The map of all captureTree loaded edges.
 */
export const selectEdgesMap = (state: RootState): EdgesMap =>
  state.revision.edgesMap;
