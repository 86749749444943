import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { isValid } from "@faro-lotv/ielement-types";
import { isRevisionScanEntity } from "@faro-lotv/service-wires";
import {
  selectPointCloudStreamForScanEntity,
  selectRevisionEntity,
  selectRevisionEntityAllChildren,
} from "../revision-selectors";

/**
 * @returns the selected entities' id in the scan tree
 * @param state current app state
 */
export function selectSelectedEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.selectedEntityId;
}

/**
 * @returns all point cloud streams for and beneath the given entity
 * @param id the entity to get the point cloud streams for
 */
function selectPointCloudStreamIdsForEntity(id: GUID) {
  return (state: RootState): GUID[] => {
    const entity = selectRevisionEntity(id)(state);

    if (!entity) {
      return [];
    }

    const children = selectRevisionEntityAllChildren(entity.id)(state);

    return [entity, ...children]
      .filter(isRevisionScanEntity)
      .map((e) => selectPointCloudStreamForScanEntity(e)(state))
      .filter(isValid)
      .map((pcs) => pcs.id);
  };
}

/**
 * @returns all point cloud streams for the selected entity
 * @param state current app state
 */
export function selectPointCloudStreamIdsForSelectedEntity(
  state: RootState,
): GUID[] | undefined {
  if (!state.dataPreparationUi.selectedEntityId) {
    return;
  }

  return selectPointCloudStreamIdsForEntity(
    state.dataPreparationUi.selectedEntityId,
  )(state);
}

/**
 * @returns all point cloud streams for the hovered entity
 * @param state current app state
 */
export function selectPointCloudStreamIdsForHoveredEntity(
  state: RootState,
): GUID[] | undefined {
  if (!state.dataPreparationUi.hoveredEntityId) {
    return;
  }

  return selectPointCloudStreamIdsForEntity(
    state.dataPreparationUi.hoveredEntityId,
  )(state);
}
