import { useCanUseSendToTool } from "@/hooks/use-can-use-send-to-tool";
import { useAppSelector } from "@/store/store-hooks";
import { ToolName } from "@/store/ui/ui-slice";
import { useToggleToolVisibility } from "@/tools/use-toggle-tool-visibility";
import { SceneFilter } from "@/types/scene-filter";
import { IElementTypeHint, isIElementImg360 } from "@faro-lotv/ielement-types";
import { useAnnotationPermissions } from "../../../hooks/use-annotation-permissions";
import { useIsPanoWithDepthAndReady } from "../walk-mode-hooks";
import { selectDepthMapForImg360 } from "../walk-scene-selectors";
import { WalkSceneActiveElement } from "../walk-types";

/**
 * Compute the visibility of toolbar tools
 *
 * @param activeElement the current active element
 * @param sceneFilter the current active scene filter
 */
export function useWalkModeToolVisibility(
  activeElement?: WalkSceneActiveElement,
  sceneFilter?: SceneFilter,
): void {
  const canUseSendTo = useCanUseSendToTool();
  const depthMap = useAppSelector(
    selectDepthMapForImg360(
      activeElement && isIElementImg360(activeElement)
        ? activeElement
        : undefined,
    ),
  );
  const isPanoFromVideo =
    activeElement &&
    isIElementImg360(activeElement) &&
    activeElement.typeHint === IElementTypeHint.odometryPath;

  const { canWriteAnnotations } = useAnnotationPermissions();

  /**
   * Allow or disallow sendTo tool to be activated according to whether the active element is a point cloud
   */
  useToggleToolVisibility(
    ToolName.sendTo,
    sceneFilter === SceneFilter.PointCloud,
    canUseSendTo,
    false,
  );

  /**
   * Allow or disallow measurement tool to be activated according to whether the active element is a point cloud
   * or a pano with depth information
   */
  const { isPanoWithDepth, isDepthReady } =
    useIsPanoWithDepthAndReady(activeElement);
  useToggleToolVisibility(
    ToolName.measurement,
    sceneFilter === SceneFilter.PointCloud ||
      sceneFilter === SceneFilter.Overlay ||
      (sceneFilter === SceneFilter.Pano && !!depthMap),
    true,
    isPanoWithDepth && !isDepthReady,
  );

  /** Allow or disallow annotation creation */
  useToggleToolVisibility(
    ToolName.annotation,
    canWriteAnnotations && !isPanoFromVideo,
    true,
    false,
  );

  /** Use clipscene tool */
  useToggleToolVisibility(
    ToolName.clipScene,
    sceneFilter !== SceneFilter.Pano,
    true,
    false,
  );
}
