import { useAppSelector } from "@/store/store-hooks";
import { Banner, CircularProgress } from "@faro-lotv/flat-ui";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  RegistrationState,
  RevisionScanEntity,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { shallowEqual } from "react-redux";
import { RevisionScansScene } from "../rendering/revision-scans-scene";
import {
  selectPointCloudStreamIdsForHoveredEntity,
  selectPointCloudStreamIdsForSelectedEntity,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { DataPreparationSidebar } from "../ui/data-preparation-sidebar";
import { PointCloudsProcessingBanner } from "../ui/point-clouds-processing-banner";

type RegistrationViewProps = {
  /** The state of the revision to register. */
  revisionState: RegistrationState;

  /** The scans that are part of the revision. */
  scanEntities: RevisionScanEntity[];

  /** The point cloud streams for the scan entities. undefined if they are still loading. */
  pointCloudStreams?: IElementGenericPointCloudStream[];
};

/**
 * @returns The registration step of the data preparation workflow.
 */
export function RegistrationView({
  revisionState,
  scanEntities,
  pointCloudStreams,
}: RegistrationViewProps): JSX.Element {
  const hoveredPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForHoveredEntity,
    shallowEqual,
  );
  const selectedPointCloudIds = useAppSelector(
    selectPointCloudStreamIdsForSelectedEntity,
    shallowEqual,
  );

  return (
    <Stack sx={{ height: "100%", width: "100%" }}>
      <RegistrationStatusBanner revisionState={revisionState} />
      <PointCloudsProcessingBanner
        scanEntities={scanEntities}
        pointCloudStreams={pointCloudStreams}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <DataPreparationSidebar
          title="Uploaded data"
          description="Register uploaded scans."
        />

        <RevisionScansScene
          scanEntities={scanEntities}
          pointCloudStreams={pointCloudStreams}
          hoveredPointCloudIds={hoveredPointCloudIds}
          selectedPointCloudIds={selectedPointCloudIds}
          overlay={<RevisionLoadingSpinner revisionState={revisionState} />}
        />
      </Stack>
    </Stack>
  );
}

type RegistrationStatusBannerProps = {
  /** The current state of the revision. */
  revisionState: RegistrationState;
};

/** @returns A banner showing the current status of the registration. */
function RegistrationStatusBanner({
  revisionState,
}: RegistrationStatusBannerProps): JSX.Element {
  switch (revisionState) {
    case RegistrationState.started:
    case RegistrationState.cloudRegistrationStarted:
      return (
        <Banner variant="info" title="In progress">
          Registration is currently in progress. Please return later.
        </Banner>
      );
    case RegistrationState.registered:
    case RegistrationState.merged:
      return (
        <Banner variant="success" title="Registration completed">
          You can now publish your data set in the next step.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
  }
}

type RevisionLoadingSpinnerProps = {
  /** The current state of the revision. */
  revisionState: RegistrationState;
};

/** @returns A loading spinner while the registration is in progress. */
function RevisionLoadingSpinner({
  revisionState,
}: RevisionLoadingSpinnerProps): JSX.Element | null {
  switch (revisionState) {
    case RegistrationState.started:
    case RegistrationState.cloudRegistrationStarted:
      return (
        <Box
          component="div"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          sx={{ display: "flex", height: "100%", pointerEvents: "none" }}
        >
          <CircularProgress size={60} />
        </Box>
      );
    default:
      return null;
  }
}
